import React from 'react'
import person1 from "../../assets/person3.jpg";
export default function BalamuruganEaswaran() {
  return (
    // <>
    //   <div class="container-fluid">
    //     <div class="row align-items-center justify-content-center p-5">
    //       <div class="col-md-3 col-12"><img src={person1} alt="" class="img-fluid" style={{ borderRadius: "50%", height: "290px", width: "300px" }} /></div>
    //       <div class="mt-3 col-md-6 col-12">
    //         <h2>Dr. Zahid Akhtar</h2>
    //         <p style={{ fontFamily: "Nunito" }}>State University of New York Polytechnic Institute C135</p>
    //         <p style={{ fontFamily: "Nunito" }}>USA</p>
    //         <p style={{ fontFamily: "Nunito", fontWeight: "bold" }}>Day 2</p>
    //         <p style={{ fontFamily: "Nunito" }}>AI and User Authentication: Adversarial Attacks and Defenses</p>
    //       </div>
    //     </div>
    //   </div>
    //   <div class="container-fluid" style={{ fontFamily: "Nunito" }}>
    //     <div class="row justify-content-center text-justify">
    //       <div class="col-8">
    //         <p>
    //           Dr. Balamurugan Easwaran having 22 years of enriching experience in the field of
    //           Academic Administration. At present working as Registrar, Texila American University,
    //           Zambia prior to this worked as a Director ICT Services & Faculty in Department of Computer
    //           and Mathematical Sciences, University of Africa Toru Orua, Nigeria. As an educationalist
    //           career is Associated with Dean (Academics and Evaluation) & Assoc. Professor (IT & IS) at
    //           BlueCrest College, Accra, Ghana, Gobi Arts & Science College, Bannari Amman Institute of
    //           Technology, Sathyamangalam, India as a Lecturer, Assistant Professor, Associate Professor
    //           in Computer Science & Applications. Proficient in preparing Affiliation process, Framing of
    //           Curriculum, Regulations and syllabus for Undergraduate and Postgraduate degree
    //           programmes.
    //           Headed various positions as Professor In charges for numerous Societies,
    //           Associations, Coordinator of Cell’s Includes Software Development Cell, Industry Institute
    //           Partnership Cell etc.
    //           The areas of research include Image Processing, Document Image Analysis, Digital
    //           Forensics, Cloud Computing, GOOGLE Apps Development, Image In-painting, Information
    //           Security, and Data Mining. Research publications include 3 Patents, numerous International,
    //           National level journals and conferences also Reviewer of various international conferences
    //           and delivered various special lectures in conferences & workshops and resource person for
    //           training Senior Secondary School Teachers in Ghana in collaboration with Ghana Education
    //           Service, Government of Ghana on Cloud Computing using Google APPS.
    //         </p>
    //       </div>
    //     </div>
    //   </div>

    // </>
    <><h1>UPDATE SOON!</h1></>
  )
}
